import SingleWalletFeature from "./SingleWalletFeature";

const WalletFeature = () => {
  return (
    <section className="relative bg-light-base py-24 dark:bg-jacarta-800">
      <div className="container">
        <div className="mx-auto mb-20 max-w-xl text-center">
          <h2 className="mb-6 text-center font-display text-3xl font-medium text-jacarta-700 dark:text-white">
            Enterprise-Grade Security for Small Merchants
          </h2>
          <p className="text-lg dark:text-jacarta-300">
            Your transactions are protected by AI-powered trust scores, dynamic
            token generation, and multi-layer authentication. Experience secure
            cross-border payments with complete peace of mind.
          </p>
        </div>

        <div className="grid gap-7 md:grid-cols-3">
          <SingleWalletFeature />
        </div>

        <p className="mt-4 text-center text-lg dark:text-jacarta-300">
          Your business is always protected with Relay.{" "}
          <a href="#" className="text-accent">
            Learn about our security features
          </a>
        </p>
      </div>
    </section>
  );
};

export default WalletFeature;
