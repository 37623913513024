import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import { testimonial_data } from "../../data/testimonial_data";
import { useRouter } from "next/router";
import pathChecking from "../../utils/pathChecking";

const Testimonial_carousel = () => {
  const testimonialData = [
    {
      id: 1,
      name: "Sarah Chen",
      position: "Small Business Owner, Singapore",
      avatar: "/images/avatars/avatar_1.jpg", // keep existing image
      comment:
        "Relay's smart wallet system has revolutionized how we handle international payments. The NFC tap feature is incredibly convenient for our customers.",
    },
    {
      id: 2,
      name: "Marcus Rodriguez",
      position: "Restaurant Chain Owner, Mexico",
      avatar: "/images/avatars/avatar_2.jpg", // keep existing image
      comment:
        "The security features give us peace of mind, and the low transaction fees have made a real difference to our bottom line. Excellent platform!",
    },
    {
      id: 3,
      name: "Emily Thompson",
      position: "Retail Store Manager, Canada",
      avatar: "/images/avatars/avatar_3.jpg", // keep existing image
      comment:
        "Integration was seamless, and the wearable payment option has been a huge hit with our tech-savvy customers. Customer support is outstanding.",
    },
    {
      id: 4,
      name: "David Kim",
      position: "E-commerce Entrepreneur, South Korea",
      avatar: "/images/avatars/avatar_4.jpg", // keep existing image
      comment:
        "The AI-powered security gives us confidence in handling international transactions. Relay has truly simplified our payment processing.",
    },
  ];

  return (
    <div className="relative">
      <Swiper
        modules={[Navigation, Pagination, Scrollbar, A11y]}
        spaceBetween={30}
        slidesPerView="auto"
        loop={true}
        breakpoints={{
          240: {
            slidesPerView: 1,
          },
          565: {
            slidesPerView: 2,
          },
          1000: {
            slidesPerView: 3,
          },
        }}
        navigation={{
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        }}
      >
        {testimonialData.map((item) => {
          const { id, avatar, name, position, comment } = item;
          return (
            <SwiperSlide key={id}>
              <div className="rounded-2.5xl bg-white p-8 dark:bg-jacarta-700">
                <blockquote className="mb-6 text-base dark:text-jacarta-300">
                  {comment}
                </blockquote>
                <div className="flex items-center space-x-4">
                  <img
                    src={avatar}
                    alt={name}
                    className="h-10 w-10 rounded-full"
                  />
                  <div>
                    <span className="block font-display text-sm text-jacarta-700 dark:text-white">
                      {name}
                    </span>
                    <span className="text-xs dark:text-jacarta-400">
                      {position}
                    </span>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>

      <div className="swiper-button-prev swiper-button-prev-1 group absolute top-1/2 -left-4 z-10 -mt-6 flex h-12 w-12 cursor-pointer items-center justify-center rounded-full bg-white p-3 text-base shadow-white-volume">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="24"
          height="24"
          className="fill-jacarta-700 group-hover:fill-accent"
        >
          <path fill="none" d="M0 0h24v24H0z" />
          <path d="M10.828 12l4.95 4.95-1.414 1.414L8 12l6.364-6.364 1.414 1.414z" />
        </svg>
      </div>
      <div className="swiper-button-next swiper-button-next-1 group absolute top-1/2 -right-4 z-10 -mt-6 flex h-12 w-12 cursor-pointer items-center justify-center rounded-full bg-white p-3 text-base shadow-white-volume">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="24"
          height="24"
          className="fill-jacarta-700 group-hover:fill-accent"
        >
          <path fill="none" d="M0 0h24v24H0z" />
          <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z" />
        </svg>
      </div>
    </div>
  );
};

export default Testimonial_carousel;
