import HelpBlock from "./HelpBlock";

const index = () => {
  return (
    <section className="relative bg-light-base py-24 dark:bg-jacarta-800">
      <div className="container">
        <div className="items-center justify-between lg:flex lg:space-x-24">
          <div className="lg:w-[45%]">
            <h2 className="mb-6 font-display text-3xl font-medium text-jacarta-700 dark:text-white">
              Ready to transform your payment experience?
            </h2>
            <p className="mb-6 text-lg dark:text-jacarta-300">
              Join thousands of merchants who are already using Relay's smart
              wallet technology for seamless cross-border transactions. Our team
              is here to help you get started.
            </p>
            <p className="dark:text-jacarta-300">
              Whether you need assistance with integration, security setup, or
              understanding our features, our dedicated support team is
              available 24/7 to ensure your success with the Relay platform.
            </p>
          </div>
          <div className="lg:w-[55%]">
            <HelpBlock />
          </div>
        </div>
      </div>
    </section>
  );
};

export default index;
