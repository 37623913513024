const SingleWalletFeature = () => {
  const walletFeatures = [
    {
      id: 1,
      icon: "smart-contract",
      title: "Smart Contract Authentication",
      text: "Secure merchant verification through our central Relay smart contract infrastructure",
    },
    {
      id: 2,
      icon: "ai-powered",
      title: "AI Trust Scoring",
      text: "Real-time risk assessment and fraud prevention powered by advanced AI algorithms",
    },
    {
      id: 3,
      icon: "security",
      title: "Dynamic Security Tokens",
      text: "Unique token generation for each transaction ensures maximum protection",
    },
  ];

  return (
    <>
      {walletFeatures.map((item) => (
        <div
          className="dark:bg-jacarta-700 dark:border-jacarta-600 border-jacarta-100 rounded-2.5xl mb-12 border bg-white p-8 text-center transition-shadow hover:shadow-xl"
          key={item.id}
        >
          <div className="mb-6 inline-flex rounded-full dark:bg-jacarta-700 dark:border-jacarta-600 border-jacarta-100 bg-[#CDBCFF] border p-3">
            <div className="inline-flex h-12 w-12 items-center justify-center rounded-full bg-accent">
              <img src={`/images/crypto-app/${item.icon}.svg`} alt="" />
            </div>
          </div>
          <h3 className="font-display text-jacarta-700 mb-4 text-lg dark:text-white">
            {item.title}
          </h3>
          <p className="dark:text-jacarta-300">{item.text}</p>
        </div>
      ))}
    </>
  );
};

export default SingleWalletFeature;
